<template>
  <div>
    <h2>{{ component.name }} (C{{ component.id }})</h2>
    <!--              {{ getComponent(c.componentId).name }} (C{{ c.componentId }}) - -->
    <ul>
      <li v-for="(mealIds,team) of teamToMealIdMap" v-bind:key="team">
        {{ team }} -
        <router-link
          v-for="mealId of mealIds" v-bind:key="mealId"
          :to="{ name:'MealPrepWork', params:{ id: mealId}}"
          class="pr-2"
        >
          M{{ mealId }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "OverAssignedComponent",
  props: {
    component: {type: Object, default: null, required: false},
    teamToMealIdMap: {type: Object, default: null, required: false}
  }
}
</script>

<style scoped>

</style>
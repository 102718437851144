<template>
  <span>
    <v-btn v-if="showPrepControls"
           class="d-print-none"
           icon
           small
           @click="$emit('show-assign-input')"
    >
      <v-icon :color="prep_instruction?'blue':''">mdi-knife</v-icon>
    </v-btn>

    <v-tooltip v-for="(icon,team) of teams"
               v-bind:key="team"
               top
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
            v-bind="attrs"
            v-on="on"
            @click="$emit('assign',item,team)"
            :class="assignment===team || 'd-print-none'"
            :color="assignment===team?'blue':''">
          {{ icon }}
        </v-icon>
      </template>
      <span>{{ team }}</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: "PrepTeamIcon",
  props: {
    prep_instruction: {type: Object, default: null, required: false},
    assignment: {type: String, default: null, required: false},
    item: {type: Object, default: null, required: false},
    showPrepControls: {type: Object, default: null, required: false},
    showAll: {type: Boolean, default: null, required: false}
  },
  computed: {
    teams() {
      if (this.showAll) {
        return this.allTeams
      }
      const assignedTeams = {};
      const assignment = this.assignment || 'no team assigned';
      assignedTeams[assignment] = this.allTeams[assignment] || 'mdi-head-question-outline';
      return assignedTeams;
    }
  },
  data() {
    return {
      allTeams: {
        protein: 'mdi-food-steak',
        starch: 'mdi-rice',
        veg: 'mdi-sprout',
        misc: 'mdi-alien',
        sauciers: 'mdi-pot-steam'
      }
    }
  }
}
</script>

<style scoped>

</style>

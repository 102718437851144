<template>
  <span>
    <v-chip outlined v-if="meal.production_priority">P{{ meal.production_priority }}</v-chip>
    <span style="text-transform: uppercase">
                {{ getDietName(meal.diet) }} - {{ dateFormatted }} -
                {{ getTimeOfDay(meal.tod) }} -
    </span>
    <span>
      {{ meal.name }}
      <router-link :to="{ name: 'MealDetail', params: { id: mealId, date: formatDate(meal.date)}}">
        M{{ mealId }}
      </router-link>
      <template v-if="!hideComponent && component.id">
         - {{ component.name }}
        <router-link
            :to="{ name: 'ComponentDetail', params: { id: component.id, date: formatDate(meal.date)}}">
              C{{ component.id }}</router-link>
      </template>
    </span>
  </span>
</template>
<script>
import {mapGetters} from 'vuex';
import {formatDate} from '@/store/utils';

export default {
  name: 'ChoppingMealDescription',
  props: {
    dateFormatted: {},
    meal: {},
    component: {type: Object, default: null, required: false},
    hideComponent: {type: Boolean, default: false, required: false}
  },
  computed: {
    ...mapGetters([
      'getDietName',
      'getTimeOfDay'
    ])
    ,
    mealId() {
      const meal = this.meal;
      return meal.meal_id || meal.id;
    }
  },
  methods: {
    formatDate
  }
}


</script>

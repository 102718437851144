<template>
  <IngredientsOrder
    :hide-headers="['category','note','price','totalPrice','actions']"
    :prep-only="true"
    :enlarge-prep="true"
    :show-ingredient-steps="showIngredients || showPrecut"
    :show-component-steps="showComponents"
    :disable-team-filter="showComponents && hasUnassigned"
    v-on:update:component-ids="componentIds=$event"
    v-on:update:steps="steps=$event"
    :exclude-allergy="excludeAllergy"
  >
    <template
      v-slot:table="{
        props : {
          ingredients, visibleHeaders, search, loading, getMealsUsingIngredient, showMeals, orders,
          components, componentIds,getCookedAmount,getRawAmount,
          getComponentMealIds,
          getComponentMealRawAmount,
          getComponentMealCookedAmount,
          formatWeightWithUnits, ingredientToMealCookedMap, ingredientToMealRawMap, steps,
          getDatesInRange, getComponentTotalCookedAmount
          }
      }">
      <v-alert type="warning"
               v-if="hasUnassigned">
        Unassigned Components. Team Filter not unavailable until this is fixed. Click the meal links below to assign a
        team to each component.
      </v-alert>
      <span v-if="loading">Loading</span>
      <div v-else>
        <!--        <pre>{{steps}}</pre>-->
        <template v-if="showComponents">
          <v-alert color="error" outlined v-if="hasUnassigned">
            The following components do not have a team assigned
            <li v-for="(teamToMealIdMap,componentId) of unassignedComponents(componentIds, steps)"
                v-bind:key="componentId">
              {{ getComponent(componentId).name }} (C{{ componentId }}) -
              <router-link
                v-for="mealId of teamToMealIdMap.unassigned" v-bind:key="mealId"
                :to="{ name:'MealPrepWork', params:{ id: mealId}}"
                class="pr-2"
              >
                M{{ mealId }}
              </router-link>
            </li>
          </v-alert>
          <v-alert color="error" outlined v-if="Object.keys(overassignedComponents(componentIds,steps)).length>0">
            The following components have more than one team assigned
            <div v-for="(teamToMealIdMap,componentId) of overassignedComponents(componentIds, steps)"
                 v-bind:key="componentId">
              <OverAssignedComponent :component="getComponent(componentId)" :teamToMealIdMap="teamToMealIdMap"/>
            </div>
          </v-alert>
          <template v-if="showSummary">
            <v-card
              v-for="component of getComponents(componentIds, getComponentMealIds)"
              v-bind:key="component.id"
              class="mb-2"
              style="page-break-inside: avoid"
            >
              <v-card-title :style="!findAssignedTeam(component.id,steps).team ? 'color: red':''">
                <PrepTeamIcon :assignment="findAssignedTeam(component.id,steps).team"/>
                {{ component.name }} (
                <router-link
                  :to="{ name: 'ComponentDetail', params: { id: component.id} }">
                  C{{ component.id }}
                </router-link>
                )
                {{ formatWeightWithUnits(getComponentTotalCookedAmount(component.id)) }}
              </v-card-title>
              <v-card-text>
                <div v-for="mealId of getComponentMealIds(component.id)" v-bind:key="mealId">
                  <v-icon>mdi-checkbox-blank-outline</v-icon>
                  {{
                    formatWeightWithUnits(getComponentMealCookedAmount(component.id, mealId))
                  }}
                  <ChoppingMealDescription
                    :meal="getMeal(mealId)"
                    :component="component"
                    :date-formatted="dateFormatted(getMeal(mealId).date, {formatString: 'dddd'})"
                  />
                  {{ getMeal(mealId).name }}
                </div>
              </v-card-text>
            </v-card>
          </template>
          <template v-if="!showSummary">
            <v-card
              v-for="component of getComponents(componentIds, getComponentMealIds)"
              v-bind:key="component.id"
              class="mb-1"
              style="page-break-inside: avoid"
            >
              <v-card-title :style="!findAssignedTeam(component.id,steps).team ? 'color: red':''">
                <v-chip outlined v-if="component.production_priority">P{{ component.production_priority }}</v-chip>
                <PrepTeamIcon :assignment="findAssignedTeam(component.id,steps).team"/>
                {{ component.name }} (C{{ component.id }})
              </v-card-title>
              <v-alert type="error" outlined v-if="overassignedComponents(componentIds,steps)[component.id]">
                The following team assignments may be a problem
                <OverAssignedComponent :component="getComponent(component.id)"
                                       :teamToMealIdMap="overassignedComponents(componentIds,steps)[component.id]"/>
              </v-alert>
              <v-card-text v-if="component.notes">
                <span class="caption">Notes for {{ component.name }}: </span>
                <br v-if="toLineArray(component.notes).length>1"/>
                <span class="dense" v-for="(line,i) of toLineArray(component.notes)" v-bind:key="i">{{
                    line
                  }}<br/></span>
              </v-card-text>
              <v-card-text v-for="mealId of getComponentMealIds(component.id)" v-bind:key="mealId">
                <v-row dense>
                  <v-col
                    cols="1"
                    class="mt-0 pt-0 d-print-none"
                    v-if="!readOnly"
                  >
                    <v-select
                      prefix="P"
                      dense
                      class=""
                      :value="getMeal(mealId).production_priority"
                      @change="updateMealPriority(getMeal(mealId),$event)"
                      :items="productionPriorities"
                      hide-details
                      hide-selected
                      persistent-hint
                    />
                  </v-col>
                  <v-col>
                    <ChoppingMealDescription
                      :meal="getMeal(mealId)"
                      :component="component"
                      :date-formatted="dateFormatted(getMeal(mealId).date, {formatString: 'dddd'})"
                    />
                  </v-col>
                </v-row>
                {{ getMeal(mealId).name }} {{
                  formatWeightWithUnits(getComponentMealCookedAmount(component.id, mealId))
                }}
                <v-btn :to="{ name:'MealPrepWork', params:{ id: mealId}}" icon>
                  <v-icon :color="!findAssignedTeam(component.id,steps).team?'blue':''">
                    mdi-knife
                  </v-icon>
                </v-btn>
                <v-row v-if="component.monosized" class="text-center font-weight-bold">
                  <v-col>
                    <v-chip outlined>
                      ALL {{
                        formatWeightWithUnits(getPortion(getMealIngredients(mealId).filter(i => i.component_id === component.id)))
                      }}
                      ({{ componentSummary(mealId, component, getDatesInRange(), orders).total }})
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row v-if="!component.monosized" class="text-center font-weight-bold">
                  <v-col v-if="componentSummary(mealId, component, getDatesInRange(), orders).small">
                    <v-chip outlined>
                      S {{
                        formatWeightWithUnits(
                          getPortion(getMealIngredients(mealId).filter(i => i.component_id === component.id))
                          * getComponentSizeRatio(component, 'small')
                        )
                      }}
                      ({{ componentSummary(mealId, component, getDatesInRange(), orders).small }})
                    </v-chip>
                  </v-col>
                  <v-col v-if="componentSummary(mealId, component, getDatesInRange(), orders).medium">
                    <v-chip outlined>
                      M
                      {{
                        formatWeightWithUnits(getPortion(getMealIngredients(mealId).filter(i => i.component_id === component.id)))
                      }}
                      ({{ componentSummary(mealId, component, getDatesInRange(), orders).medium }})
                    </v-chip>
                  </v-col>
                  <v-col v-if="componentSummary(mealId, component, getDatesInRange(), orders).large">
                    <v-chip outlined>
                      L
                      {{
                        formatWeightWithUnits(
                          getPortion(getMealIngredients(mealId).filter(i => i.component_id === component.id))
                          * getComponentSizeRatio(component, 'large')
                        )
                      }}
                      ({{ componentSummary(mealId, component, getDatesInRange(), orders).large }})
                    </v-chip>
                  </v-col>
                </v-row>
                <MealRestrictionSummary
                  :meal="getMeal(mealId)"
                  :orders="orders.filter(o=>o.meal_id==mealId)"
                  :selected-component-id="component.id"
                  :only-problems="true"
                />
                <v-row dense>
                  <v-col class="d-print-none">
                    <v-btn outlined @click="includeAllergy=!includeAllergy">{{ includeAllergy ? 'exclude' : 'include' }}
                      allergy orders
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-alert dense outlined v-if="includeAllergy" type="warning">Warning: includes allergy amounts
                    </v-alert>
                    <v-alert dense outlined v-if="!includeAllergy" type="info">Does not include allergy amounts ('extra
                      protein' and 'extra large' are included)
                    </v-alert>
                  </v-col>
                </v-row>
                <MealDetailTable
                  :meal-id="mealId"
                  :read-only="true"
                  :title="component.name"
                  subtitle="summary ? Object.entries(summary).join(' ') : ''"
                  :component="component || {}"
                  :ingredients="getMealIngredients(mealId).filter(i => i.component_id===component.id)"
                  :summary="includeAllergy? getOrdersForMeal(mealId,getDatesInRange()) : getSummary(mealId, component, orders)"
                  :show-prep-controls="true"
                  :show-nutrient-controls="false"
                />
              </v-card-text>
            </v-card>
          </template>
        </template>
        <template v-if="showIngredients">
          <v-card v-for="item of ingredients" v-bind:key="item.foodid" class="mb-1" style="page-break-inside: avoid">
            <v-card-title class="my-0 py-0">
              <div>
                {{ item.nameOnLabel.toLowerCase() }} [{{ extractFoodId(item) }}] |
                {{ formatWeightWithUnits(item.cookedAmountValue) }}
                <template v-if="item.buyAmountValue!==item.rawAmountValue">- (pre trim
                  {{ formatWeightWithUnits(item.buyAmountValue) }})
                </template>

                <span
                  v-if="formatWeightWithUnits(item.cookedAmountValue)!==formatWeightWithUnits(item.rawAmountValue)"> (raw {{
                    formatWeightWithUnits(item.rawAmountValue)
                  }})</span>
              </div>
            </v-card-title>
            <v-card-text v-for="meal of getMealsUsingIngredient(item)"
                         v-bind:key="meal.id" class="large-prep my-0 py-0">
              <div v-for="i of getMealFoods(meal.id,item.foodid)"
                   v-bind:key="[i.mealid,i.foodid,i.component_id].join(',')">
                <template v-if="getCookedAmount(i)">
                <span class="highlight-instruction">
                  <v-icon>mdi-checkbox-blank-outline</v-icon>
                  {{ formatWeightWithUnits(getCookedAmount(i)) }}
                  <span
                    v-if="formatWeightWithUnits(getRawAmount(i))!==formatWeightWithUnits(getCookedAmount(i))">
                    (raw {{ formatWeightWithUnits(getRawAmount(i)) }})
                  </span>
                  | {{ item.nameOnLabel.toLowerCase() }}
                  <!-- use the step definition if it exists -->
                  <template v-if="steps[meal.id]">
                    <span v-if="steps[meal.id].ingredients[i.id]">
                      | <span v-if="steps[meal.id].ingredients[i.id].team">[{{
                        steps[meal.id].ingredients[i.id].team
                      }}]</span>
                      {{ steps[meal.id].ingredients[i.id].instruction }}
                    </span>
                    <!-- otherwise use prep instruction -->
                    <span v-if="!steps[meal.id].ingredients[i.id] && i.prep_instruction">| {{
                        i.prep_instruction
                      }}</span>
                  </template>
                </span>
                  <span v-if="showMeals">
            <br/>
            <ChoppingMealDescription
              class="ml-7"
              :date-formatted="dateFormatted(meal.date, {formatString: 'dddd'})"
              :meal="meal"
              :component="getComponent(i.component_id)"
            />
             :: raw portion
                    <template v-if="getComponent(i.component_id).monosized">
                      | {{
                        formatWeightWithUnits(i.rawAmount)
                      }}
                      ({{ componentSummary(meal.id, getComponent(i.component_id), getDatesInRange(), orders).total }})
                    </template>
                    <template v-if="!getComponent(i.component_id).monosized">
                    <template
                      v-if="componentSummary(meal.id, getComponent(i.component_id), getDatesInRange(), orders).small"
                    >
                    | S
                    {{
                        formatWeightWithUnits(i.rawAmount * getComponentSizeRatio(getComponent(i.component_id),'small'))
                      }}
                      ({{ componentSummary(meal.id, getComponent(i.component_id), getDatesInRange(), orders).small }})
                      </template>
                    <template
                      v-if="componentSummary(meal.id, getComponent(i.component_id), getDatesInRange(), orders).medium"
                    >
                    | M {{
                        formatWeightWithUnits(i.rawAmount)
                      }}
                      ({{ componentSummary(meal.id, getComponent(i.component_id), getDatesInRange(), orders).medium }})
                    </template>
                    <template
                      v-if="componentSummary(meal.id, getComponent(i.component_id), getDatesInRange(), orders).large"
                    >

                    | L {{
                        formatWeightWithUnits(i.rawAmount * getComponentSizeRatio(getComponent(i.component_id),'large'))
                      }}
                      ({{ componentSummary(meal.id, getComponent(i.component_id), getDatesInRange(), orders).large }})
                    </template>
                    </template>
           </span>
                </template>
              </div>
            </v-card-text>
          </v-card>
        </template>
        <template v-if="showPrecut">
          <v-card
            v-for="({items,cookedAmountValue,rawAmountValue,ingredientName,mealsUsingIngredient,byPrepInstruction})
            of groupByName(ingredients,getMealsUsingIngredient,steps, getCookedAmount, getRawAmount)"
            v-bind:key="ingredientName" class="mb-1" style="page-break-inside: avoid">
            <v-card-title class="my-0 py-0">
              <div>
                {{ ingredientName }}
                <!--                [{{ items.map(item => extractFoodId(item)) }}] |-->
                <span> raw {{
                    formatWeightWithUnits(rawAmountValue)
                  }}</span>
                <span v-if="formatWeightWithUnits(cookedAmountValue)!==formatWeightWithUnits(rawAmountValue)">
                  (cooked {{ formatWeightWithUnits(cookedAmountValue) }})
                </span>
              </div>
            </v-card-title>

            <!--            <pre>{{ mealsUsingIngredient }}</pre>-->
            <!--            <v-card-text v-for="{meal,foodid} of mealsUsingIngredient" v-bind:key="meal.id" class="large-prep my-0 py-0">-->
            <!--              {{ { meal, foodid } }}-->
            <!--            </v-card-text>-->
            <!--            {{ Object.keys(byPrepInstruction) }}-->
            <v-card-text>
              <v-row>
                <v-col>
                  <div
                    v-for="{instruction, cookedAmountValue, rawAmountValue } of byPrepInstruction"
                    v-bind:key="instruction"
                  >
                    <strong>
                <span>
                  raw {{ formatWeightWithUnits(rawAmountValue) }}
                </span>
                      <span v-if="formatWeightWithUnits(cookedAmountValue)!==formatWeightWithUnits(rawAmountValue)">
                  (cooked {{ formatWeightWithUnits(cookedAmountValue) }})
                </span>
                    </strong>
                    : {{ instruction }}
                  </div>
                </v-col>
                <v-col class="text-right" cols="3" style="font-size: large">
                  <input type="checkbox" style="width: 40px; height: 40px;"/> washed
                </v-col>
                <v-col class="text-right" cols="3" style="font-size: large">
                  <input type="checkbox" style="width: 40px; height: 40px;"/> done by
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </div>
    </template>
  </IngredientsOrder>
</template>


<script>
import IngredientsOrder from '@/components/IngredientsOrder';
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from 'moment';
import {
  calculateSummary,
  dateFormatted,
  extractFoodId,
  filterByAllergyDetected,
  getComponentSizeRatio, getRawComponentAmountForMeal
} from '@/store/utils';
import MealDetailTable from '@/components/MealDetailTable';
import ChoppingMealDescription from '@/views/ChoppingMealDescription';
import MealRestrictionSummary from '@/components/MealRestrictionSummary';
import PrepTeamIcon from '@/components/PrepTeamIcon';
import api from '@/api';
import OverAssignedComponent from "@/components/OverAssignedComponent";
import urlState from "@/router/urlState";


export default {
  mixins: [urlState],
  components: {
    OverAssignedComponent,
    PrepTeamIcon, MealRestrictionSummary, ChoppingMealDescription, MealDetailTable, IngredientsOrder
  },
  props: {
    showComponents: {type: Boolean, default: false, required: false},
    showIngredients: {type: Boolean, default: false, required: false},
    showPrecut: {type: Boolean, default: false, required: false},
    showSummary: {type: Boolean, default: false, required: false},
    excludeAllergy: {type: Boolean, default: false, required: false},
    readOnly: {type: Boolean, default: false, required: false},
  },
  data() {
    return {
      dates: null,
      componentIds: [],
      steps: null,
      includeAllergy: false
    }
  },
  mounted() {
    this.syncToUrl({
      param: 'includeAllergy', urlParam: 'inc_allergy', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    });
    const query = JSON.parse(JSON.stringify(this.$route.query || {}));
    if (!query.teams && this.showPrecut) {
      query.teams = ['veg', '(blank)'];
      this.$router.push({query});
    }
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    })
  },
  computed: {
    ...mapState(['productionPriorities']),
    ...mapGetters([
      'getDietColor',
      'getDietName',
      'getTimeOfDay',
      'getMealIngredients',
      'getComponent',
      'getOrderSummaryForMeal',
      'getMeal'
    ]),
    hasUnassigned() {
      return Object.keys(this.unassignedComponents(this.componentIds, this.steps)).length > 0;
    }
  },
  // watch : {
  //   ingredients(ingredients){
  //     this.mealIds = [...new Set([...ingredients.map(i=>i.mealid)])];
  //     console.log('meal ids', this.mealIds);
  //   }
  // },
  methods: {
    ...mapActions([
      'fetchMeal'
    ]),
    filterByAllergyDetected,
    formatDate(date) {
      // console.log('foramt date',[ date, moment(date).utc().format(moment.HTML5_FMT.DATE)]);
      return moment(date).utc().format(moment.HTML5_FMT.DATE);
    },
    dateFormatted,
    getMealFoods(meal_id, foodid) {
      const mealIngredients = this.getMealIngredients(meal_id);
      return mealIngredients && mealIngredients.filter(i => i.foodid === foodid)
    },
    toLineArray(str) {
      return str && str.split('\n');
    },
    extractFoodId,
    findSteps(steps, componentId) {
      return Object.values(steps)
        .map(s => s.components[componentId])
        .filter(v => !!v)
        .map(({meal_id, component_id, team}) => `${team} assigned to M${meal_id} C${component_id} `)
    },
    findAssignedTeams(componentId, steps) {
      const componentMealAssignments = Object.values(steps || {})
        .map(s => s.components[componentId])
        .filter(v => !!v)
        .map(({meal_id, component_id, team}) => ({meal_id, component_id, team}));
      const result = {};
      componentMealAssignments.forEach(({team, meal_id}) => {
        if (team === null) {
          team = 'unassigned';
        }
        result[team] = result[team] || [];
        result[team].push(meal_id);
      })
      return result;
    },
    findAssignedTeam(componentId, steps) {
      const componentMealAssignments = Object.values(steps || {})
        .map(s => s.components[componentId])
        .filter(v => !!v)
        .map(({meal_id, component_id, team}) => ({meal_id, component_id, team}));
      const mealIds = componentMealAssignments.map(a => a.meal_id);
      const teams = componentMealAssignments.map(a => a.team);
      const team = teams[0];
      const allSameTeam = teams.every(t => t == team);
      if (!allSameTeam && allSameTeam) {
        api.showError({message: `more than one team was assigned to component C${componentId} : ${teams.join(',')}`});
      }
      return {componentId, team, mealIds}
    },
    getComponents(componentIds, getComponentMealIds) {
      const componentToMealIdMap = {};
      const componentPriorityMap = {};
      for (const cId of componentIds) {
        componentToMealIdMap[cId] = getComponentMealIds(cId);
        for (const mealId of getComponentMealIds(cId)) {
          const meal = this.getMeal(mealId);
          const {production_priority} = meal;
          if (production_priority) {
            if (!componentPriorityMap[cId]) {
              componentPriorityMap[cId] = production_priority;
            } else if (componentPriorityMap[cId] > production_priority) {
              componentPriorityMap[cId] = production_priority;
            }
          }
        }
      }

      function sortKey(a) {
        const priority = componentPriorityMap[a.id] || 9;
        return `${priority} ${a.name}`;
      }

      return componentIds
        .map(id => ({...this.getComponent(id), production_priority: componentPriorityMap[id]}))
        .sort((a, b) => sortKey(a).localeCompare(sortKey(b)));
    },
    unassignedComponents(componentIds, steps) {
      // return componentIds;
      return Object.fromEntries(
        componentIds
          .map(componentId => [componentId, this.findAssignedTeams(componentId, steps)])
          .filter(e => !!e[1].unassigned)
      );
      // return componentIds.map(componentId => this.findAssignedTeams(componentId, steps))
      //   .filter(a => !!a.unassigned);
      // // return componentIds.filter(componentId => this.findAssignedTeam(componentId, steps).team);
    },
    overassignedComponents(componentIds, steps) {
      // return componentIds;
      return Object.fromEntries(
        componentIds
          .map(componentId => [componentId, this.findAssignedTeams(componentId, steps)])
          .filter(e => Object.keys(e[1]).length > 1)
      );
      // return componentIds.filter(componentId => this.findAssignedTeam(componentId, steps).team);
    },
    getOrdersForMeal(mealId, dates) {
      const orderSummaryForMeal = {};
      dates.forEach(date => {
        const orderSummaryForMealOnDate = this.getOrderSummaryForMeal({date, mealId});
        Object.keys(orderSummaryForMealOnDate)
          .forEach(k => {
            orderSummaryForMeal[k] = orderSummaryForMeal[k] || 0;
            orderSummaryForMeal[k] += orderSummaryForMealOnDate[k];
          })
      })
      return orderSummaryForMeal;
    },
    getOrdersForComponent(componentId, orders, excludeRestrictions = false) {
      console.log('getOrdersForComponent', componentId, orders, excludeRestrictions);
      const orderSummaryForMeal = {};
      return orderSummaryForMeal;
    },
    groupByName(ingredients, getMealsUsingIngredient, steps, getCookedAmount, getRawAmount) {
      const result = {};
      for (const item of ingredients) {
        let priority;
        for (const meal of getMealsUsingIngredient(item)) {
          const {production_priority} = meal;
          if (production_priority && (!priority || production_priority < priority)) {
            priority = production_priority;
          }
        }
        const name = `${priority ? (' (P' + priority + ') ') : ''}${item.nameOnLabel}`;
        result[name] = result[name] || {
          ingredientName: name.trim(),
          items: [],
          cookedAmountValue: 0,
          rawAmountValue: 0,
          mealsUsingIngredient: [],
          byPrepInstruction: {}
        };
        const compoundItem = result[name];
        compoundItem.items.push(item);
        compoundItem.cookedAmountValue += item.cookedAmountValue;
        compoundItem.rawAmountValue += item.rawAmountValue;
        const foodid = item.foodid;
        compoundItem.mealsUsingIngredient.push(
          ...getMealsUsingIngredient(item)
            .map(meal => ({meal, foodid}))
        );
        for (const meal of getMealsUsingIngredient(item)) {
          for (const i of this.getMealFoods(meal.id, foodid)) {
            const instruction = (steps[meal.id] && (steps[meal.id].ingredients[i.id] || {}).instruction) || 'no instruction';
            const rawAmount = getRawAmount(i);
            if (rawAmount && rawAmount > 0) {
              compoundItem.byPrepInstruction[instruction] = compoundItem.byPrepInstruction[instruction]
                || {
                  instruction,
                  items: [],
                  cookedAmountValue: 0,
                  rawAmountValue: 0,
                };
              compoundItem.byPrepInstruction[instruction].cookedAmountValue += getCookedAmount(i);
              compoundItem.byPrepInstruction[instruction].rawAmountValue += rawAmount;
              compoundItem.byPrepInstruction[instruction].items.push(i);
            }
          }
        }
      }
      return Object
        .entries(result)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(e => e[1]);
    },
    getOrdersFilteredByAllergyDetected(mealId, component, orders) {
      orders = orders.filter(o => o.meal_id == mealId);
      const selectedFoodIds = this.getMealIngredients(mealId).filter(i => i.component_id === component.id).map(i => i.foodid);
      orders = filterByAllergyDetected(orders, selectedFoodIds, false);
      return orders;
    },
    getSummary(mealId, component, orders) {
      return calculateSummary(this.getOrdersFilteredByAllergyDetected(mealId, component, orders));
    },
    getCount(orders) {
      return orders.reduce((sum, o) => o.quantity + sum, 0);
    },
    componentSummary(mealId, component, datesInRange, orders) {
      return this.includeAllergy ? this.getOrdersForMeal(mealId, datesInRange) : this.getSummary(mealId, component, orders);
    },
    getPortion(ingredients) {
      return ingredients
        .filter(i => !i.ignore_prep_weight)
        .reduce((sum, i) => i.cookedAmount + sum, 0);
    },
    updateMealPriority(meal, priority) {
      const productionPriority = priority === 'clear' ? null : priority;
      console.log('updateMealPriority', meal, productionPriority);
      return api.put(`v2/meal/${meal.id}`, {meal: {production_priority: productionPriority}})
        .then(r => console.log('saved', r))
        .then(() => this.fetchMeal({id: meal.id, force: true}));
    },
    getComponentSizeRatio,
    getRawComponentAmountForMeal,
  },

}
</script>

<style>


.large-prep {
  font-size: 14px
}

.highlight-instruction {
  font-size: medium;
  text-transform: uppercase;
  color: black;
  font-weight: bolder;
  /*text-decoration: underline;*/
}

</style>
